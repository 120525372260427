/* global SYMPHONY */
/* global APP_CONFIG */

import * as AppApi from './services/api'

const { APP_ID, APP_TITLE, APP_ICON, APP_MODULE_URL } = window.APP_CONFIG;
const controllerService = SYMPHONY.services.register(`${APP_ID}:controller`);
const dependencies = [
    'modules',
    'applications-nav',
    'extended-user-info'
];

const authenticate = async ({ pod }) => {
    console.log(`${APP_ID}:controller:authenticate`, pod);
    localStorage.setItem('POD_ID',pod);
    let { appToken } = await AppApi.authenticate();
    if (appToken) return { appToken }
}

const register = ({ appToken }) => {
    console.log(`${APP_ID}:controller:register`);
    const appInfo = { appId: APP_ID }
    if (appToken) appInfo.tokenA = appToken;
    return SYMPHONY.application.register(appInfo, dependencies, [`${APP_ID}:controller`]);
}

const addNav = () => {
    console.log(`${APP_ID}:controller:addNav`);
    const navService = SYMPHONY.services.subscribe('applications-nav');
    navService.add(
        `${APP_ID}-app`,
        { title: APP_TITLE, icon: APP_ICON },
        `${APP_ID}:controller`
    );
}

const implement = () => {
    console.log(`${APP_ID}:controller:implement`);

    controllerService.implement({
        select: controllerSelect
    })
}

const controllerSelect = (itemId) => {
    console.log(`${APP_ID}:controller:select`, { itemId });
    const modulesService = SYMPHONY.services.subscribe('modules');
    if (itemId == `${APP_ID}-app`) {
        modulesService.show(
            itemId,
            APP_TITLE,
            `${APP_ID}:controller`,
            `${APP_MODULE_URL}`,
            { canFloat: true },
        );
        modulesService.focus(itemId);
    }
}

const init = () => {
    console.log(`${APP_ID}:controller:init`);
    localStorage.removeItem('POD_ID');
    SYMPHONY.remote.hello()
        .then(authenticate)
        .then(register)
        .then(addNav)
        .then(implement)
        .catch(e => console.error(`${APP_ID}:controller:init issue with controller`, e))
}

export default () => {
    init();
    return (
        <div>App Controller</div>
    );
};

import { useState } from 'react';
import { Box, Text, NavTabs, MessageBox , Separator} from 'symphony-bdk-ui-toolkit';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Button from './Button';
import { ContactList } from './ContactList';
import * as AppApi from '../services/api';
import { Nav, NavItem, NavProps, style} from '@symphony-ui/uitoolkit-components';

const { APP_EMAIL_LINK_TARGET } = window.APP_CONFIG;

const symphonyUserTemplate = (x) => {

    const initials = x.firstName && x.lastName && `${x.firstName.slice(0, 1)}${x.lastName.slice(0, 1)}`;
    const emailAddress = x.emailAddress != null ? x.emailAddress.slice(0, 2) : 'N/A' ;
    const isConnected = x.id && x.emailAddress;
    const link = "symphony://?startChat=" + x.id;
    console.log(link);
    const isExt = (userId) => {
        const userIdBinary = userId.toString(2);
        const podIdBinary = userIdBinary.substring(0, userIdBinary.length - 36);
        const podId = parseInt(podIdBinary, 2);
        return podId != localStorage.getItem('POD_ID');
    }

    return (
        <Box horizontal align="center">
            <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                height: '32px', width: '32px',
                border: isExt(x.id) ? '2px #F8C43F solid' : '2px #EAEBEC solid',
                borderRadius: '50%',
                color: isExt(x.id) ? '#F8C43F' : '#008EFF',
                textTransform: 'uppercase'
            }}>
                {initials || emailAddress} 
            </div>

            <div>
                <Text>{x.displayName}
                {isExt(x.id) && (<span style={{ color: '#7C7F86', marginLeft: '0.5rem' }}>{x.company}</span>)}
                {!isExt(x.id) && (<span style={{ color: '#7C7F86', marginLeft: '0.5rem' }}>{x.company}</span>)}
                </Text>
            </div>

            {isExt(x.id) && (<div style={{
                color: '#17181B',
                padding: '1px 4px',
                borderRadius: '2px',
                backgroundColor: '#F8C43F'
            }}>EXT</div>)}
            {isConnected && (<a href={link}><Button style={{ width: 50, float: 'right' }} size = "small" fill = "filled">
                 CHAT
                </Button></a>)}
        </Box>
    )
}
// Jainam55@yahoo.co.in, jainam55@gmail.com, jainam.shah@symphony.com, houssain.barouni@symphony.com, sharon.hackett@symphony.com
export const Results = (props) => {

    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeItemId, setActiveItemId] = useState(0);

    const onActiveTabChange = (navItem: NavItem) => {
      setActiveItemId(navItem.id);
    };
    let alertStyle = {
        display: 'none'
    };
    alert && (alertStyle = {
        ...alertStyle,
        display: 'block'
    });

    const { connected, notConnected, setNotConnected, notFound, activeTab, setNotFound,  startOver} = props;
    
    const onConnect = async () => {
        const selectedForConnect = notConnected.filter(x => x.isChecked);
        setLoading(true);
        if (selectedForConnect.length > 100) {
            console.log('onConnect - too many selected');
            setLoading(false);
            setAlert(`Too many selected. For now, limited to 100 connection requests at a time.`);
            setTimeout(() => setAlert(''), 4000);
            return;
        }

        const ids = selectedForConnect.map(x => x.id);

        const connectResults = await AppApi.connectMany(ids);
        console.log('onConnect.connectResults', { connectResults });
        setLoading(false);
        setAlert(`Connection requests sent to ${connectResults.length} contacts!`);

        setTimeout(() => setAlert(''), 4000);

        setNotConnected(notConnected.map(x => ({ ...x, isChecked: false })));
    }
    
    const onInvite = async () => {
        setLoading(true);
        var pos = { width: 601, height: 700 };
        pos.left = Math.floor((window.outerWidth - pos.width) / 2);
        pos.top = Math.floor((window.outerHeight - pos.height) / 2);
        const selectedForConnect = notFound.filter(x => x.isChecked);

        const emails = selectedForConnect.map(x => x.emailAddress);
        const inviteResults = await AppApi.sendInvite(emails);
        setLoading(false);
        setAlert(`Invitation requests sent to ${selectedForConnect.length} contacts!`);
        setTimeout(() => setAlert(''), 15000);
        setNotFound(notFound.map(x => ({ ...x, isChecked: false })));

    }

    const getName = (contact) => {
        let name = contact.displayName || contact.emailAddress.substring(0, contact.emailAddress.indexOf("@"));
        return name.toLowerCase().split(/[\s\.]/)
            .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
            .join(' ');
    }
    // if(loading)
    //     {
    //         return <CircularProgress />
    //     }

    return (
        <Box>
        <div style={{ width : '760px'}}>
            <Nav
                    items={[
                        {
                          id: 0,
                          label: <Text size="large" style={{
                            textTransform: 'none'}}>Not Connected ({notConnected.length})</Text>
                        },
                        {
                          id: 1,
                          label: <Text size="large" style={{
                            textTransform: 'none'}}>Not on Symphony ({notFound.length})</Text>
                        },
                        {
                          id: 2,
                          label: <Text size="large" style={{
                            textTransform: 'none'}}>Connected ({connected.length})</Text>
                        }
                      ]}
                    onActiveTabChange={onActiveTabChange}
                    activeItemId={activeItemId}
                />
           <div className="tk-ml-2" style={{ width : '760px'}}>
                    {activeItemId === 0 && (
                    <div label={`NOT CONNECTED (` + notConnected.length + `)`}>
                    <Box>
                    <div></div>
                        <Text>{notConnected.length} contact{notConnected.length != 1 && 's'} found in Symphony you are not connected to yet.</Text>
                        {loading ? <CircularProgress /> : ''}
                        <MessageBox style={alertStyle}>{alert}</MessageBox>
                        
                        {!!notConnected.length && (
                            <Box horizontal>
                                <ContactList startOver = {startOver} action={{labelforModal : 'Connection request', label: 'CONNECT', on: onConnect }} items={notConnected} setItems={setNotConnected} itemTemplate={symphonyUserTemplate} />
                            </Box>
                        )}
                        {notConnected.length < 1 && <Separator />}
                        {notConnected.length < 1 && (  <Box horizontal align="right">
                                <Stack direction="row" spacing={5}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <Button style={{float: 'right'}} fill = "outlined" onClick={startOver} >
                                    START OVER
                                </Button>
                                </Stack>
                            </Box>
                            )}
                    </Box>
                </div>)}

                {activeItemId === 1 && (
                     <div>
                     <Box>
                     <div></div>
                         {!notFound.length && (
                             <Text>Congratulations! All contacts are existing Symphony users.</Text>
                         )}
                         {!!notFound.length && (
                             <Text>
                                Imported {notFound.length} contact{notFound.length != 1 && 's'} who are/is not on Symphony. Let your contacts know that they can sign-up to Symphony by contacting <a href="mailto:sales@symphony.com">sales@symphony.com</a>.
                             </Text>
                         )}
                         {!!notFound.length && (<Separator/>)}
                         {loading ? <CircularProgress /> : ''}
                         <MessageBox style={alertStyle}>{alert}</MessageBox>
                         {!!notFound.length && (
                             <ContactList items={notFound} startOver = {startOver} setItems={setNotFound} 
                                 itemTemplate={(x) => {
                                     const initials = x.firstName && x.lastName && `${x.firstName.slice(0, 1)}${x.lastName.slice(0, 1)}`;
                                     return (
                                         <Box horizontal align="center">
                                             <div style={{
                                                 display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                 height: '32px', width: '32px',
                                                 border: '2px #C6CACA solid', borderRadius: '50%',
                                                 color: '#C6CACA', textTransform: 'uppercase'
                                             }}>
                                                 {initials || x.emailAddress.slice(0, 2)}
                                             </div>
                                             <Box type="flat" my="2px" style={{ width: 350 }}>
                                                 <Text>{getName(x)}</Text>
                                                 <Text>{x.emailAddress}</Text>
                                             </Box> 
                                         </Box>                                        
                                     )
                                 }} />
                         )}
                         {notFound.length < 1 && <Separator />}
                         <Box horizontal align="right">
                         <Stack direction="row" spacing={5}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <Button style={{float: 'right'}} fill = "outlined" onClick={startOver} >
                                    START OVER
                                </Button>
                                </Stack>
                             </Box>
                     </Box>
                 </div>)}
                 {activeItemId === 2 && (
                     <div>
                     <Box>
                         <div></div>
                         <Text>
                             {connected.length > 0 && 'Congratulations! '}
                             {connected.length} contact{connected.length != 1 && 's'} found in your existing Symphony connections.
                         </Text>
                         {!!connected.length && (<Separator/>)}
                         {!!connected.length && (
                             <ContactList startOver = {startOver} items={connected} itemTemplate={symphonyUserTemplate} />
                          )}
                          {connected.length < 1 && <Separator />}
                          <Box horizontal align="right">
                                 <Stack direction="row" spacing={5}>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <Button style={{float: 'right'}} fill = "outlined" onClick={startOver} >
                                     START OVER
                                 </Button>
                                 </Stack>
                             </Box>
                     </Box>
                 </div>)}


                </div>
                </div>
        
        </Box>
    )
}
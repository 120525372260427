/* global SYMPHONY */
/* global APP_CONFIG */

import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { THEMES, RestClient } from 'symphony-bdk-ui-toolkit';

import { ContactImport } from './components/ContactImport';

const { APP_ID } = window.APP_CONFIG;

const connect = () => {
    console.log(`${APP_ID}:app:connect`);
    return SYMPHONY.application.connect(
        APP_ID,
        ['modules', 'applications-nav', 'extended-user-info'],
        [`${APP_ID}:app`],
    )
}

const getJwt = (data) => {
    console.log(`${APP_ID}:app:getJwt`, data);
    const userService = SYMPHONY.services.subscribe('extended-user-info');
    if (userService) {
        userService.getJwt()
            .then(jwt => {
                console.log('contactimport:app:getJwt:jwt');
                RestClient.setJwt(jwt);
            });
    }
}

export default () => {

    const [theme, setTheme] = React.useState('');

    useEffect(async () => {
        console.log(`${APP_ID}:app:init`);
        await SYMPHONY.remote.hello()
            .then(({ pod, themeV2 }) => {
                console.log(`${APP_ID}:app:hello`, { pod, themeV2 });
                setTheme(themeV2.name)
            })
            .then(connect)
            .then(getJwt)
            .catch(e => console.error(`${APP_ID}:app:init issue with app view`, e));
    }, []);

    return (
        <ThemeProvider theme={(theme == 'dark') ? THEMES[1] : THEMES[0]}>
            <ContactImport />
        </ThemeProvider>
    );
};
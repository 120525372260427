import React from 'react';
import { useState, useRef } from 'react';
import { Box, Text, Separator } from 'symphony-bdk-ui-toolkit';
import Stack from '@mui/material/Stack';
import Button from './Button';
import {Modal, ModalTitle, ModalBody, ModalFooter} from '@symphony-ui/uitoolkit-components';
import '@symphony-ui/uitoolkit-components/styles';

export const ContactList = props => {

    const{startOver} = props;

    const [show, setShow] = useState(false);
        const handleClose = () => {
            setShow(false);
        };
        const handleOpen = () => {
            {props.action.on};
            setShow(false);
        };
    
    const onCheckAll = (checked) => {
        let _items = props.items.map(item => {
            item.isChecked = checked
            return item;
        });
        props.setItems(_items);
    }
    const onCheckAllcheckbox = (event) => {
        let _items = props.items.map(item => {
            item.isChecked = event.target.checked
            return item;
        });
        props.setItems(_items);
    }

    const handleCheckChieldElement = (event) => {
        let _items = props.items.map(item => {
            if (item.id == event.target.value) {
                item.isChecked = event.target.checked
            }
            return item;
        })
        props.setItems(_items);
    }

    const checkedItems = () => {
        return props.items.filter(item => item.isChecked);
    }

    if(!props.items || props.items.length == 0) {
        return (
            <Box>
                 <Box type="flat">
                    <Text>No contacts to search.</Text>
                 </Box>
            </Box>
        )
    }

    return (
        <Box>
            {props.action && (
                <Box>
                <Text style={{width:300}}><b>Imported Contacts</b> ({`${checkedItems().length} Selected`})</Text>
                <Separator />
                </Box>
                
            )}
            {props.action && (
                <Box horizontal align="center">
                    {/* <Button disabled={checkedItems().length <= 0} onClick={props.action.on} >
                        {props.action.label}
                    </Button>
                    <Button fill="outlined" onClick={() => onCheckAll(true)} style={{ width: 'auto' }} >
                        Select All
                    </Button>
                    <Button fill="outlined" onClick={() => onCheckAll(false)} style={{ width: 'auto' }} >
                        Select None
                    </Button> */}
                    <input type="checkbox"
                                onChange={onCheckAllcheckbox}
                                checked = {props.items.every((item) => item.isChecked)}
                                style={{width: '20px', height: '15px'}}  />
                    <Text>SELECT ALL</Text>
                </Box>
            )}
            <div style={{overflow : 'auto', height : '200px', width : '900'}}>
            <Box type="flat">
                {props.items.map((item, index) => (
                   
                    <Box key={index} horizontal align="center" style={{ margin: '.3rem 0' }}>
                        {props.action && (
                            <input type="checkbox"
                                onChange={handleCheckChieldElement}
                                checked={item.isChecked}
                                value={item.id}
                                style={{width: '20px', height: '15px'}} />
                        )}
                        {props.itemTemplate && props.itemTemplate(item) || (
                            <Text>{item.text}</Text>
                        )}
                    </Box>
                   
                ))}
                
            </Box>
            </div>
            {props.action && (
            <div>
                    <Modal size="medium" closeButton show={show} onClose={handleClose}>
                    <ModalTitle>{props.action.label}</ModalTitle>
                    <ModalBody> {props.action.labelforModal} will be sent to {checkedItems().length} contacts</ModalBody>
                    <ModalFooter>
                    <Button variant={'primary'} onClick= { () => {props.action.on(); handleClose();}} >
                        Confirm
                    </Button>
                    <div style = {{width : '5px'}}> </div>
                    <Button variant={'tertiary'} onClick={handleClose}>
                        Cancel
                    </Button>
                    </ModalFooter>
                </Modal>
                </div>
            )}
            <Separator />
            {props.action && (
                <Box horizontal align="right">
                    <Stack direction="row" spacing={5}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <Button style={{float: 'right'}} disabled={checkedItems().length <= 0} onClick={() => setShow(true)} >
                        {props.action.label}
                    </Button>
                    <Button style={{float: 'right'}} fill = "outlined" onClick={startOver} >
                        START OVER
                    </Button>
                    </Stack>
                </Box>
            )}
        </Box>
    )
}


import {  Button, Loader, MessageBox, Text } from 'symphony-bdk-ui-toolkit';
import LinearProgress  ,{ LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import { ContactList } from './ContactList';
import * as AppApi from '../services/api';
import { useState, useEffect } from 'react';

const sortByEmail = (a, b) => {
    if (!a.emailAddress || !b.emailAddress) return 0;
    if (a.emailAddress.toUpperCase() < b.emailAddress.toUpperCase()) return -1;
    return 1;
};

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
         <Text> <Typography variant="body2" >{`${Math.round(
            props.value,
          )}%`}</Typography></Text>
        </Box>
      </Box>
    );
  }
  const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
  

export const Search = (props) => {

    const { contacts, setContacts, onSearchComplete } = props;

    const [msgBox, setMsgBox] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const onSearch = async () => {

        setLoading(true);

        const emails = contacts
            .filter(x => x.isChecked)
            .map(item => item.emailAddress);

        setProgress(5);
        await sleep(200);
        try {
            setProgress (20);
            const searchResults = await AppApi.searchMany(emails);
            await sleep(100);
            setProgress (70);
            let userList = [];
            for (let i = 0; i < searchResults.length; i++) {
                const searchResult = searchResults[i];
        
                const emailAddress = searchResult.searchQuery.query;
                let _users = [{ id: i, emailAddress, status: 'NOT_FOUND' }];
                if (searchResult.users.length > 0) {
                    _users = searchResult.users.map(user => ({ emailAddress, status: ((user.emailAddress) ? 'CONNECTED' : 'NOT_CONNECTED'), ...user }))
                }
                userList = [...userList, ..._users];
            }
            await sleep(200);
            setProgress (95);
            await sleep(200);
            userList.sort(sortByEmail);

            onSearchComplete(userList);
        } catch (err) {
            console.log({ err });
            setMsgBox({ message: `Search failed. Please try again. Error : ${err.message}`, type: 'error' });
        }

        setLoading(false);
    }

    useEffect(() => {
        onSearch();
      }, []);


    return (
        <Box>
            
            {/* <Box>{msgBox && (<a style={{ cursor: 'pointer' }} onClick={() => setMsgBox(null)} title="Click  to dismiss"><MessageBox type={msgBox.type || 'info'}>{msgBox.message}</MessageBox></a>)}</Box> */}
            {loading ?    <LinearProgressWithLabel value={progress} />: 
            <Box>{msgBox && (<a style={{ cursor: 'pointer' }} onClick={() => setMsgBox(null)} title="Click  to dismiss"><MessageBox type={msgBox.type || 'info'}>{msgBox.message}</MessageBox></a>)}</Box>}


        </Box>
    );
}
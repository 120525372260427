/* global APP_CONFIG */

import { RestClient } from 'symphony-bdk-ui-toolkit';

const { APP_API_URL } = window.APP_CONFIG;
const getPodId = () => localStorage.getItem('POD_ID');

RestClient.baseUrl = APP_API_URL || 'http://localhost:8080/symphony';

export const authenticate = async () => {
    const url = `/authenticate`;
    const params = { pod: getPodId() };
    const resp = await RestClient.get(url, params);
    if (resp.status && resp.status == 200) return resp.data;
};

export const user = async () => {
    const url = `/user`;
    const params = { pod: getPodId() };
    const resp = await RestClient.get(url, params);
    if (resp.status && resp.status == 200) return resp.data;
};

export const users = async (emails) => {
    const url = `/users?email=${emails.slice(0, 100).join(',')}`;
    const params = { pod: getPodId() };
    const resp = await RestClient.get(url, params);
    if (resp.status && resp.status == 200) return resp.data;
};

export const searchOne = async (email) => {
    const url = `/search`;
    const data = { email };
    const params = { pod: getPodId() };
    const resp = await RestClient.post(url, data, params);
    if (resp.status && resp.status == 200) return resp.data;
};

export const searchMany = async (emails) => {
    const url = `/search`;
    const data = { emails };
    const params = { pod: getPodId() };
    const resp = await RestClient.post(url, data, params);
    if (resp.status && resp.status == 200) return resp.data;
};
//test change
export const sendInvite = async (emails) => {
    const url = `/invite`;
    const data = { emails };
    const params = { pod: getPodId() };
    const resp = await RestClient.post(url, data, params);
    if (resp.status && resp.status == 200) return resp.data;
};
// ......................

export const connectMany = async (ids) => {
    const url = `/connect`;
    const data = { ids };
    const params = { pod: getPodId() };
    const resp = await RestClient.post(url, data, params);
    if (resp.status && resp.status == 200) return resp.data;
};

export const test = async () => {
    const url = `/send/mlyLie5PP1vS6y7DowgnBn___o0cH8mQdA`;
    const params = {};
    const resp = await RestClient.get(url, params);
    if (resp.status && resp.status == 200) return resp.data;
};
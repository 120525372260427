
import { useState, useRef } from 'react';
import { Box, Separator, Text, ProgressIndicator , Dropdown} from 'symphony-bdk-ui-toolkit';
import { Icon, Badge } from '@symphony-ui/uitoolkit-components';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';



import { Import } from './Import';
import {CustomizedDialogs} from './importDialog';
import { Search } from './Search';
import { Results } from './Results';
import * as AppApi from '../services/api';
import Button from './Button';


export const ContactImport = () => {

    const [currStep, setCurrStep] = useState(1);
    const [contacts, setContacts] = useState([]);
    const [connected, setConnected] = useState([]);
    const [notConnected, setNotConnected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState([]);

    const searchRef = useRef();
    const resultsRef = useRef();

    
    const onImportComplete = (contacts) => {
        setContacts(contacts);
        setCurrStep(2);
    };


    const onSearchComplete = (results) => {
        setConnected(results.filter(x => x.status == 'CONNECTED'));
        setNotConnected(results.filter(x => x.status == 'NOT_CONNECTED').map(x => ({ isChecked: true, ...x })));
        setNotFound(results.filter(x => x.status == 'NOT_FOUND').map(x => ({ isChecked: true, ...x })));;
        setCurrStep(3);
    };

    const startOver = () => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
        setCurrStep(1);
        setContacts([]);
        setConnected([]);
        setNotConnected([]);
        setNotFound([]);
    }

    return (
        <Box>

            {currStep === 1 && (
                <Box>
                    <div style={{ width : '760px'}}>
                    {/* <Text isTitle type="primary"> 1. Import <progress style = {{width:"30%"}} id="file" max="100" value="0"> Import </progress> 2. Connect </Text> */}
                    <Text><h3><b>Welcome to the Contact Import App - a tool to help you connect through Symphony with people you already know outside your organization.</b></h3>
                    <CustomizedDialogs />
                     </Text>
                        </div>
                        <Separator />
                    <Import onImportComplete={onImportComplete} />
                    <Separator />
                </Box>
            )}
            {currStep === 2 && (
                <Box>
                    <Text type="primary" size="small">Searching {contacts.length} contacts, Please Wait..</Text>
                    <Search contacts={contacts} setContacts={setContacts} onSearchComplete={onSearchComplete} />
                    
                    <Box horizontal><Button fill="outlined"  onClick={startOver}>Clear &amp; Start Over</Button></Box>
                    <Separator />
                </Box>
            )}
            {currStep === 3 && (
                <Box>
                    <div ref={resultsRef} />
                    {/* <Text isTitle type="primary"> 1. Import <CheckCircleIcon color = 'success'fontSize="small"/><progress style = {{width:"30%"}} id="file" max="100" value="100"> Import </progress> 2. Connect </Text> */}
                    <Text> <b>Review</b> the contacts that are already in Symphony and who you are not connected to yet.</Text>
                    <Results connected={connected} startOver = {startOver} notConnected={notConnected} setNotConnected={setNotConnected} notFound={notFound} setNotFound={setNotFound}/>
                    {/* <Box horizontal><Button fill="outlined" onClick={startOver}>Clear &amp; Start Over</Button></Box> */}
                    <Separator />
                </Box>
            )}
        </Box>
    );
}
import { useState } from 'react';
import styled from 'styled-components';
import xml from "xml-js";
import Fab from '@mui/material/Fab';
import { Box , Text} from 'symphony-bdk-ui-toolkit';
import GoogleButton from 'react-google-button';

import Button from '@mui/material/Button';

const ButtonContent = styled.span`
    font-weight: 500;
    padding: 10px 10px 10px 0px; 
    `;

const extractTitleFromEntry = (entry) => {
    if (entry.title && entry.title._text) {
        return titleize(entry.title._text);
    } else if (
        entry["gd:name"] &&
        entry["gd:name"]["gd:fullName"] &&
        entry["gd:name"]["gd:fullName"]._text
    ) {
        return titleize(entry["gd:name"]["gd:fullName"]._text);
    } else if (
        entry["gd:name"] &&
        entry["gd:name"]["gd:givenName"] &&
        entry["gd:name"]["gd:givenName"]._text &&
        entry["gd:name"]["gd:familyName"] &&
        entry["gd:name"]["gd:familyName"]._text
    ) {
        return `${titleize(entry["gd:name"]["gd:givenName"]._text)} ${titleize(
            entry["gd:name"]["gd:familyName"]._text
        )}`;
    } else if (entry["gd:name"] && entry["gd:name"]["gd:givenName"]._text) {
        return titleize(entry["gd:name"]["gd:givenName"]._text);
    } else if (entry["gd:name"] && entry["gd:name"]["gd:familyName"]._text) {
        return titleize(entry["gd:name"]["gd:familyName"]._text);
    } else if (
        entry["gd:email"] &&
        entry["gd:email"]._attributes &&
        entry["gd:email"]._attributes.address
    ) {
        return titleize(
            entry["gd:email"]._attributes.address.replace(/@.*/, "").replace(".", " ")
        );
    }

    return null;
}

const extractEmailFromEntry = (entry) => {
    return entry["gd:email"]._attributes.address;
}

const titleize = (string) => {
    return string.toLowerCase().replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
}


export const GoogleContactImport = (props) => {

    if (window.opener && window.location.hash && window.location.hash.includes('state=get_contacts')) {
        let fragmentString = window.location.hash.substring(1);
        let params = {}, regex = /([^&=]+)=([^&]*)/g, m;
        while (m = regex.exec(fragmentString)) {
            params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
        }
        window.opener.GOOGLE_ACCESS_TOKEN = params.access_token;
        window.close();
        return (<div></div>)
    }

    const {
        clientId,
        maxResults = 5000,
        onSuccess = (res) => console.log('GoogleContactImport onSuccess', res),
        onFailure = (err) => console.log('GoogleContactImport onFailure', err),
        children = 'Google Contacts',
    } = props;

    const [isLoading, setIsLoading] = useState(false);

    const Icon = () => (
        <div style={{ marginRight: 7, padding: 1, borderRadius: 2 }}>
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 44" width="28px" height="20px">
                <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
                <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
                <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
                <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
            </svg>
        </div>
    )

    const onClick = async (e) => {
        setIsLoading(true);
        try {
            let token = await getGoogleAuthToken();
            let contacts = await getGoogleContacts(token);
            setIsLoading(false);
            onSuccess(contacts);
        } catch (err) {
            setIsLoading(false);
            onFailure(err.message);
        }
    }

    const getGoogleAuthToken = async () => {
        var pos = { width: 601, height: 700 };
        pos.left = Math.floor((window.outerWidth - pos.width) / 2);
        pos.top = Math.floor((window.outerHeight - pos.height) / 2);

        var googleAuthWindow = window.open('about:blank', 'googleAuth',
            `width=${pos.width} height=${pos.height} left=${pos.left} top=${pos.top}`);

        var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth?';
        var oauth2Params = {
            'client_id': clientId,
            'redirect_uri': window.location.href,
            'state': 'get_contacts',
            'response_type': 'token',
            'prompt': 'consent',
            //'scope': 'https://www.googleapis.com/auth/contacts.readonly',
            'scope' : 'https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/contacts.readonly'
        };
        for (var p in oauth2Params) {
            oauth2Endpoint += `${p}=${oauth2Params[p]}&`;
        }
        googleAuthWindow.location = oauth2Endpoint;

        return new Promise((resolve, reject) => {
            var timer = setInterval(() => {
                if (googleAuthWindow.closed) {
                    clearInterval(timer);
                    resolve(window.GOOGLE_ACCESS_TOKEN);
                    delete window.GOOGLE_ACCESS_TOKEN;
                }
            }, 800);
        })
    }

    const getGoogleContacts = async (token) => {
        if(!token) throw new Error('Google Auth Token Missing');
        //let params = { "max-results": maxResults };
        let params = {"readMask" : "emailAddresses,names", "pageSize" : "1000"};
        //let url = `https://www.google.com/m8/feeds/contacts/default/full?${new URLSearchParams(params)}`;
        //let url = `https://people.googleapis.com/v1/{resourceName=people/me}/connections`;
        let url = `https://people.googleapis.com/v1/otherContacts?${new URLSearchParams(params)}`;
       // let headers = { "GData-Version": "3.0", Authorization: `Bearer ${token}` };
        let headers = { Authorization: `Bearer ${token}`};
        let raw = await fetch(url, { headers })
            .then(res => res.text());
        let parsed = JSON.parse(raw);
        return handleRawContactData(parsed);
    }

    const handleRawContactData = (response) => {
        // Now let's parse the XML...
        // Iterate over each contact.
        const results = [];
       console.log(response.otherContacts.length);
        for(let i = 0; i < response.otherContacts.length; i++){
            const emailTest = response.otherContacts[i].emailAddresses[0].value;
            //const titleName = response.value[i].displayName;
            const text = emailTest.trim();
            results.push({
                //title : titleName,
                email : emailTest
            });
        }

        return results;
    }

    return (
    //     <GoogleButton
    //     type = 'dark'
    // onClick={onClick}
    //       />
    <div>
    <Button variant="outlined"  sx = {{backgroundColor : 'transparent',borderRadius : '30px', minHeight : "10px",  textTransform: 'none', fontSize : '15px'}} onClick={onClick}startIcon={ <Icon key={2} />}>
    <Text>IMPORT FROM GOOGLE</Text>
</Button>
    </div>
    );
}

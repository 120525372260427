import React from 'react';

const Footer = () => {
    const buildSHA = process.env.REACT_APP_BUILD_SHA || 'COMMIT_SHA_HERE';
    const buildDate = process.env.REACT_APP_BUILD_DATE || 'BUILD_DATE_HERE';
    const copyrightYear = (buildDate.getFullYear) ? buildDate.getFullYear() : (new Date()).getFullYear();

    return (
        <div style={{ margin: '2rem' }}>
            <p style={{ textAlign: 'center', color: '#616161' }}>
                Copyright © {copyrightYear} Symphony Communication Services, LLC. All Rights Reserved
            </p>
            <p style={{ textAlign: 'center', color: '#616161' }}>
            <a href="https://symphony.com/privacy-policy/">Privacy Policy</a> &bull;  <a href=" https://symphony.com/terms-of-service/">Terms of Service</a> 
            </p>
        </div>
    )
}

export default Footer
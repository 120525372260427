/* global SYMPHONY */
/* global APP_CONFIG */

import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

import Controller from './controller';
import App from './app';
import Header from './components/Header';
import Footer from './components/Footer';

ReactDOM.render(
  <div style={{ margin: '2rem' }}>
    <Header />
    <BrowserRouter>
      <Switch>
        <Route exact path="/controller.html">
          <Controller />
        </Route>
        <Route exact path="/app.html">
          <App />
        </Route>
        <Route path="/">
          Default Home
      </Route>
      </Switch>
    </BrowserRouter>
    <Footer />
  </div>,
  document.getElementById('root')
);
